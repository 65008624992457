import {  CheckCheck,Languages,MessagesSquare,Milestone,RefreshCcwDot, StepForward, WrapText,Lightbulb,Pen,FileText,Palette,Atom,User,Hammer,Text,Building2,Pill,PersonStanding,Footprints,Map,Clapperboard,CaseLower} from "lucide-react";
import { useEditor } from "novel";
import { getPrevText } from "novel/utils";
import { CommandGroup, CommandItem, CommandSeparator } from "../ui/command";
// https://lucide.dev/icons/


const options = [
  {
    value: "fix",
    label: "Grammar",
    icon: CheckCheck,
  },
  {
    value: "simplify",
    label: "Simplify",
    icon: Text,
  },
  {
    value: "rephrase",
    label: "Rephrase",
    icon: WrapText,
  },
  {
    value: "vocabulary",
    label: "Vocabulary",
    icon: CaseLower,
  }
];

const translator_options = [
  {
    value: "text2en",
    label: "To English",
    icon: Languages,
  },
  {
    value: "text2zh",
    label: "To Chinese",
    icon: Languages,
  }
];


const roles_options = [
  {
    value: "philosopher",
    label: "Philosopher",
    icon: User,
  },
  {
    value: "artist",
    label: "Artist",
    icon: Palette,
  },
  {
    value: "scientist",
    label: "Scientist",
    icon: Atom,
  },
  {
    value: "engineer",
    label: "Engineer",
    icon: Hammer,
  },
  {
    value: "entrepreneur",
    label: "Entrepreneur",
    icon: Building2,
  },
  {
    value: "doctor",
    label: "Doctor",
    icon: Pill,
  },
];

const actions_options = [
  {
    value: "brainstorm",
    label: "Brainstorm",
    icon: Lightbulb,
  },
  {
    value: "discuss",
    label: "Discuss",
    icon: MessagesSquare,
  }
];

interface AISelectorCommandsProps {
  onSelect: (value: string, option: string) => void;
}

const AISelectorCommands = ({ onSelect }: AISelectorCommandsProps) => {
  const { editor } = useEditor();

  return (
    <>
      <CommandGroup heading="Edit">
      <div className="grid grid-cols-2 gap-1">
        {options.map((option) => (
          <CommandItem
            onSelect={(value) => {
              const slice = editor.state.selection.content();
              const text = editor.storage.markdown.serializer.serialize(slice.content);
              onSelect(text, value);
            }}
            className="flex gap-2 px-4"
            key={option.value}
            value={option.value}
          >
            <option.icon className="h-4 w-4 text-blue-500 dark:text-slate-200 " />
            {option.label}
          </CommandItem>
        ))}
        </div>
      </CommandGroup>

      <CommandSeparator />

      <CommandGroup heading="Translate">
      <div className="grid grid-cols-2 gap-1">
      {translator_options.map((option) => (
        <CommandItem
          onSelect={(value) => {
            const slice = editor.state.selection.content();
            const text = editor.storage.markdown.serializer.serialize(slice.content);
            onSelect(text, value);
          }}
          className="flex gap-2 px-4"
          key={option.value}
          value={option.value}
        >
          <option.icon className="h-4 w-4 text-blue-500 dark:text-slate-200 " />
          {option.label}
        </CommandItem>
      ))}
      </div>
      </CommandGroup>

      <CommandSeparator />
      
      <CommandGroup heading="Roles">
       {/* display multi CommandItem in one row */}
       <div className="grid grid-cols-2 gap-1">
        {roles_options.map((option) => (
          <CommandItem
            onSelect={(value) => {
              const slice = editor.state.selection.content();
              const text = editor.storage.markdown.serializer.serialize(slice.content);
              onSelect(text, value);
            }}
            className="flex gap-2 px-4"
            key={option.value}
            value={option.value}
          >
            <option.icon className="h-4 w-4 text-blue-500 dark:text-slate-200" />
            {option.label}
          </CommandItem>
        ))}
        </div>
      </CommandGroup>

      <CommandSeparator />

      <CommandGroup heading="Actions">

      <div className="grid grid-cols-2 gap-1">
      {actions_options.map((option) => (
        <CommandItem
          onSelect={(value) => {
            const slice = editor.state.selection.content();
            const text = editor.storage.markdown.serializer.serialize(slice.content);
            onSelect(text, value);
          }}
          className="flex gap-2 px-4"
          key={option.value}
          value={option.value}
        >
          <option.icon className="h-4 w-4 text-blue-500 dark:text-slate-200" />
          {option.label}
        </CommandItem>
      ))}
      </div>
      </CommandGroup>

      <CommandSeparator />

      {/* <CommandSeparator />
    
      <CommandGroup heading="Experiment">
        <CommandItem
          onSelect={() => {
            const pos = editor.state.selection.from;

            const text = getPrevText(editor, pos);
            onSelect(text, "continue");
          }}
          value="continue"
          className="gap-2 px-4"
        >
          <StepForward className="h-4 w-4 text-blue-500 dark:text-slate-200" />
          Continue writing
        </CommandItem>
      </CommandGroup> */}
    </>
  );
};

export default AISelectorCommands;
